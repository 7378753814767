import axios from "axios"
import React, { useEffect, useState } from "react"
import ActivatedLayout from "../ActivatedLayout"
import Activate from "./states/Activate"
import { MEMBERSHIP_STATUS } from "../../utils/membership"
import { HIG_MEMBERSHIP_ACTIVATED_REDIRECT_URL, URL_ACTIVATION_DETAILS } from "../../utils/api"
import { StringParam, useQueryParam } from "use-query-params"
import { IActivateOrderData } from "../../utils/schema-types"
import { useError } from "../ErrorHandler/ErrorHandler"
import { redirect } from "../../utils/common";
import {isHigPage} from "../../utils/shopify";

const Activation = () => {
  const [orderData, setOrderData] = useState({} as IActivateOrderData | {})
  const [activationStatus, setActivationStatus] = useState<MEMBERSHIP_STATUS>(
    MEMBERSHIP_STATUS.UNSENT
  )
  const [isActivationChecked, setIsActivationChecked] = useState(false)

  const [token] = useQueryParam("token", StringParam)
  const [isAuthorized] = useQueryParam("authorized", StringParam)

  const errorHeader = useError()

  useEffect(() => {
    isAuthorized ? getActivationDetails() : authorize()
  }, [])
  const getActivationDetails = () => {
    axios
      .get(URL_ACTIVATION_DETAILS, {
        params: {
          token,
        },
        withCredentials: true,
      })
      .then(response => {
        const data = response.data as IActivateOrderData

        setOrderData(data)

        setActivationStatus(data.activation_status)
        setIsActivationChecked(true)
        console.log("GET DATA>..", orderData, response.data)

        if (data.activation_status === MEMBERSHIP_STATUS.ACTIVATED) {
          if (isHigPage()) window.location.replace(HIG_MEMBERSHIP_ACTIVATED_REDIRECT_URL)
          // Skip if activated - Allow UI to navigate to Activated state regardless of logged in / errors / etc
        } else if (
          data.shopify_order_id === null ||
          data.shopify_order_id === undefined
        ) {
          errorHeader.setErrorMessage("Order not found.")
        } else if (data.activation_status === MEMBERSHIP_STATUS.INVALID) {
          errorHeader.setErrorMessage(
            `Invalid membership activation for ${data.shopify_order_name}.`
          )
        } else if (!data.oxefit_user_email) {
          errorHeader.setErrorMessage(
            `We had a problem connecting your OxeFit user account for ${data.shopify_order_name}.`
          )
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const authorize = () => redirect(token)

  if (!isAuthorized) return null
  if (!isActivationChecked) return null

  return (
    <>
      {activationStatus === MEMBERSHIP_STATUS.ACTIVATED ? (
        <ActivatedLayout orderData={orderData as IActivateOrderData} />
      ) : (
        <Activate
          orderData={orderData as IActivateOrderData}
          setCurrentActivationState={setActivationStatus}
        />
      )}
    </>
  )
}

export default Activation
