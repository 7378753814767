import React from "react"
import { View, OxefitLogo } from "../ActivateLayout/ActivateLayout.styles"
import {
  TextBig,
  TextSmall,
  CardContainer,
  Card,
  XIcon,
  Title,
  Subtitle,
  HeaderBackgroundPinnacle,
  HeaderBackgroundPower,
  HeaderBackgroundPulse,
  OxefitQrImg,
  HigQrImg,
  CardContent,
  Cardlines,
  LineImg,
  GooglePlayImg,
  AppStoreImg,
  Service,
  SupportIcon,
  FAQIcon,
  ViewHeader,
  TextServices,
  TitleAlpha,
  HeaderBackgroundHig,
} from "./ActivatedLayout.styles"
import { TEXT_ALIGN } from "../Text/Text"
import { supportURL, FAQsURL } from "../../utils/constants"
import {
  orderSkuToMembershipType,
  MEMBERSHIP_TYPE,
} from "../../utils/membership"
import { IActivateOrderData } from "../../utils/schema-types"

export interface ActivatedLayoutProps {
  orderData: IActivateOrderData
}

const renderHeaderBackground = (type: MEMBERSHIP_TYPE, children: any) => {
  switch (type) {
    case MEMBERSHIP_TYPE.POWER:
      return <HeaderBackgroundPower>{children}</HeaderBackgroundPower>
    case MEMBERSHIP_TYPE.PULSE:
      return <HeaderBackgroundPulse>{children}</HeaderBackgroundPulse>
    case MEMBERSHIP_TYPE.PINNACLE:
      return <HeaderBackgroundPinnacle>{children}</HeaderBackgroundPinnacle>
    case MEMBERSHIP_TYPE.HIG:
      return <HeaderBackgroundHig>{children}</HeaderBackgroundHig>
  }
}

export default function ActivatedLayout(props: ActivatedLayoutProps) {
  const membershipType = orderSkuToMembershipType(props.orderData!)
  const isHig = membershipType === MEMBERSHIP_TYPE.HIG
  // for HIG membership redirect to their welcome page

  let content = isHig ?  "HIG Membership" : `OxeFit ${membershipType}`
  const logo = isHig ? null : <OxefitLogo />;
  const qrCode = isHig ? <HigQrImg /> : <OxefitQrImg />

  const appStoreLink = isHig ? "https://apps.apple.com/us/app/golf-fitness-by-hit-it-great/id1600475009" : "https://apps.apple.com/us/app/oxefit/id1536140882"
  const googlePlayLink = isHig ? "https://play.google.com/store/apps/details?id=com.golfcourse.dev" : "https://play.google.com/store/apps/details?id=com.oxefit.one"

  return (
    <View>
      <ViewHeader>
        {logo}
      </ViewHeader>
      {renderHeaderBackground(
        membershipType,
        <>
          {/* <XLogo /> */}
          <Subtitle size={20} color="#ffffff">
            Membership Activated
          </Subtitle>
          <Title size={34} uppercase>
            Welcome to <span className="break" />
            {content}
            <TitleAlpha>Welcome</TitleAlpha>
          </Title>
        </>
      )}
      {isHig ? null : <XIcon />}
      <TextBig
        size={34}
        uppercase={false}
        color="#051C2C"
        align={TEXT_ALIGN.CENTER}
      >
        Get The {isHig ? "HIG" : "OxeFit"} App
      </TextBig>
      <TextSmall align={TEXT_ALIGN.CENTER}>
        Now that your membership has been activated, take a moment to explore
        the wealth of content available on the {isHig ? "HIG" : "OxeFit"} app.
      </TextSmall>
      <CardContainer>
        <Card>
          <CardContent>
            {qrCode}
            <Subtitle size={14} align={TEXT_ALIGN.CENTER}>
              Scan the QR code to download the {isHig ? "HIG" : "OxeFit"} app
            </Subtitle>
            <Cardlines>
              <LineImg />
              <Subtitle size={14} align={TEXT_ALIGN.CENTER}>
                or
              </Subtitle>
              <LineImg />
            </Cardlines>
            <Cardlines>
              <AppStoreImg href={appStoreLink} />
              <GooglePlayImg href={googlePlayLink} />
            </Cardlines>
          </CardContent>
        </Card>
        {isHig ? null : <Service>
          <CardContent>
            <FAQIcon />
            <TextServices align={TEXT_ALIGN.CENTER}>
              Find an answer
            </TextServices>
            <TextSmall size={14} align={TEXT_ALIGN.CENTER}>
              Have a question for us about the XS1? Check out the FAQs on our
              website.
            </TextSmall>
            <a href={FAQsURL}>
              <TextSmall size={14} align={TEXT_ALIGN.CENTER}>
                Browse FAQs
              </TextSmall>
            </a>
          </CardContent>
          <CardContent>
            <SupportIcon />
            <TextServices align={TEXT_ALIGN.CENTER}>Get Support</TextServices>
            <TextSmall size={14} align={TEXT_ALIGN.CENTER}>
              Need to speak to a member of the OxeFit team? Find out how to get
              in touch.
            </TextSmall>
            <a href={supportURL}>
              <TextSmall size={14} align={TEXT_ALIGN.CENTER}>
                Contact us
              </TextSmall>
            </a>
          </CardContent>
        </Service>
        }
      </CardContainer>
    </View>
  )
}
