import styled from "styled-components"
// import { media } from "../../utils/media"

const Header = styled.div<{ error: boolean }>`
  ${props => (props.error ? `margin-top: 0px;` : "margin-top: -64px;")}
  transition: margin-top 0.2s ease-out;
  min-height: 64px;
  width: 100%;
`

export const ErrorHeader = styled(Header)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  color: #ff3939;
  background-color: white;
  a {
    color: #ff3939;
  }
`

export const ErrorFill = styled.div<{ error: boolean }>`
  position: fixed;
  ${props => (props.error ? `display: block;` : "display:none;")}
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: black;
  ${props => (props.error ? `opacity: 0.35;` : "opacity:0")}
  transition: opacity 3s ease-out;
  pointer-events: all;
  z-index: 1000;
`