import React from "react"
import { Container } from "./Modal.styles"

type ModalProps = {
  children: JSX.Element
  className?: string
}

const Modal = ({ children, className }: ModalProps) => {
  return <Container className={className}>{children}</Container>
}

export default Modal
