import styled from "styled-components"
import Button from "../Button"
import Text from "../Text"
import PinnacleBackground from "../../assets/membership-pinnacle-bg.png"
import PulseBackground from "../../assets/membership-pulse-bg.png"
import PowerBackground from "../../assets/membership-power-bg.png"
import HigBackground from "../../assets/hig/hig-background-form.png"
import { media } from "../../utils/media"
import OxefitLogotype from "../../assets/icons/oxefitLogoWhite.inline.svg"
import XLogotype from "../../assets/icons/XLogoWhite.inline.svg"

export const View = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  background: #e5e5e5;

  ${media.small} {
    background: #ffffff;
  }
`
export const ViewHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0px;
  position: absolute;
  width: 100%;
  top: 0px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: none;
  /* backdrop-filter: blur(14px); */

  ${media.small} {
    background: none;
    backdrop-filter: none;
  }
`
export const LinearGradient = styled.div`
  background: linear-gradient(
    180deg,
    #567abb -1.04%,
    #53a3da 51.52%,
    #4cc3cb 100%
  );
  height: 500px;
  width: 100%;
  align-items: stretch;

  ${media.medium} {
    height: 440px;
  }
  ${media.small} {
    height: 440px;
  }
`
export const RadialGradient = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 30px;

  ${media.medium} {
    padding: 10px;
  }

  background: radial-gradient(
    216.33% 127.29% at -9.63% -7.6%,
    #44dec6 0%,
    #2682d8 70.95%,
    #3a4be6 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ButtonBase = styled<any>(Button)`
  mix-blend-mode: normal;
  align-self: center;
  margin: 20px;
  display: flex;
  transition: width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  font-family: "Resolve Sans";
  font-weight: 600;
  pointer-events: ${props => (props.enabled! ? "all" : "none")};
  ${media.small} {
    margin: 20px 0 0 0;
  }
`

/** Header. */
export const ButtonWhite = styled(ButtonBase)`
  margin-top: 30px;
  background: white;
  color: #772583;

  &:hover {
    opacity: 0.7;
  }
`

/** Footer */
export const ButtonBlue = styled(ButtonBase)`
  background: #ce7de0;
  color: white;

  &:hover {
    background: #67a0ff;
  }
`

export const Card = styled.div`
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 10px 37px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 40px;
  display: flex;
  flex-direction: column;

  ${media.small} {
    box-shadow: none;
  }
`
export const PinnacleBackgroundImage = styled.div`
  border-radius: 12px 12px 0px 0px;
  align-self: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 51.84%, #000000 100%);
  background-image: url(${PinnacleBackground});
  background-repeat: no-repeat;
  background-size: cover;
  align-self: stretch;
  background-position: center center;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`
export const PowerBackgroundImage = styled(PinnacleBackgroundImage)`
  background-image: url(${PowerBackground});
`
export const PulseBackgroundImage = styled(PinnacleBackgroundImage)`
  background-image: url(${PulseBackground});
`
export const HigBackgroundImage = styled(PinnacleBackgroundImage)`
  background-image: url(${HigBackground});
`
export const CardContainer = styled.div`
  align-self: center;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: -80px; */

  ${media.medium} {
    /* margin-top: -60px; */
  }
  ${media.small} {
    /* margin-top: -40px; */
  }
`

export const Activities = styled.img`
  padding-bottom: 30px;
`
export const TextTiny = styled(Text)`
  line-height: 140%;
  margin: 30px 10px 0 10px;
`
export const TextSmall = styled(TextTiny)`
  max-width: 510px;
  margin: 16px 0;
`

export const TextMemHeader = styled(TextTiny).attrs({ size: 34 })`
  max-width: 510px;
  margin: 40px 0;
  font-weight: 700;

  ${media.medium} {
    font-size: 24px;
  }
`

export const ActivityDescription = styled(TextTiny)`
  margin: 8px 0px;
`

export const CardText = styled(Text)`
  line-height: 140%;
  align-self: stretch;
  margin: 40px 0px;
`

export const TextDescription = styled(Text)`
  line-height: 140%;
  max-width: 640px;
`

export const TextEmail = styled(Text)`
  font-weight: 600;
`

export const TextSwitchAccount = styled(Text)`
  margin: 30px 0 20px 0;
  a {
    color: white;
    cursor: pointer;
    text-decoration: underline;
  }
`

export const TermsAndConditions = styled(CardText)`
  ${media.small} {
    margin-top: 0px;
  }
`
export const TextBig = styled(Text)`
  font-family: "Resolve Sans Regular WD";
  line-height: 100%;
  letter-spacing: -0.01em;
  margin: 16px 0;

  ${media.small} {
    font-size: 20px;
  }
`
export const PinnacleActivityTitle = styled(TextBig)`
  background: linear-gradient(
    61.37deg,
    #ff876a 0%,
    #ff876a 0.01%,
    #b580d1 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 12px 0;
`
export const PulseActivityTitle = styled(PinnacleActivityTitle)`
  background: linear-gradient(
    61.37deg,
    #b580d1 0%,
    #b580d1 0.01%,
    #6c95f7 100%
  );
  -webkit-background-clip: text;
`
export const PowerActivityTitle = styled(PinnacleActivityTitle)`
  background: linear-gradient(
    61.37deg,
    #a2e4b8 0%,
    #a2e4b7 0.01%,
    #5294ff 100%
  );
  -webkit-background-clip: text;
`
export const OxefitLogo = styled(OxefitLogotype)`
  height: 20px;
  width: 150px;
`
export const XLogo = styled(XLogotype)`
  height: 27px;
  width: 200px;
`
