import styled from "styled-components"

export const Container = styled.div`
  padding: 16px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f7f7f7;
  border-radius: 8px;
  width: 240px;
`
export const Title = styled.div`
  color: #697780;
  font-size: 14px;
  margin-bottom: 4px;
`
export const Value = styled.div`
  color: #051c2c;
  font-size: 20px;
  font-family: "Resolve Sans Regular WD";
`
