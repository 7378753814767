import styled from "styled-components"

interface ButtonProps {
  disabled?: boolean
}

export const ButtonContainer = styled.button<ButtonProps>`
  padding: 12px 24px;
  font-size: 16px;
  color: #ffffff;
  background: linear-gradient(98.98deg, #ce7de0 -21.97%, #67a0ff 120.65%);
  border-radius: 30px;
  border: none;
  font-weight: normal;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
  background: #F7F7F7;
  color: #77258333;
    `}
`
