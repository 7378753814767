import Logo from "../../assets/icons/oxefitLogo.inline.svg"
import styled from "styled-components";
import background from "../../assets/hig/logo.png";


export const HigLogo = styled.div`
  height: 55px;
  width: 182px;
  background-image: url(${background});
  background-size: cover;
`

export const OxefitLogo = Logo