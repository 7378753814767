import styled from "styled-components"

export const Input = styled.input`
  padding: 22px 24px 12px 24px;
  min-width: 320px;
  width: 100%;
  height: 56px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(24, 22, 20, 0.2);
  box-sizing: border-box;
  border-radius: 28px;
  margin: 12px 0px 8px 0px;
  font-size: 14px;
  color: #051c2c;
  &:focus {
    outline: none;
  }
  &:focus ~ label,
  &:not(:placeholder-shown) ~ label {
    top: 12px;
    font-size: 10px;
  }
`

export const Label = styled.label`
  height: 20px;
  color: #697780;
  font-size: 14px;
  position: absolute;
  top: 28px;
  left: 24px;
  display: flex;
  align-items: flex-end;
  pointer-events: none;
  transition: all 0.3s;
`

export const Container = styled.div`
  position: relative;
`

export const ErrorContainer = styled.div`
  color: #ff5151;
  padding: 5px 0 0 5px;
`
