import styled from "styled-components"

export const ProfitBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  padding-bottom: 20px;
  align-items: center;
  gap: 16px;
`
export const ProfitText = styled.div`
  position: static;
  left: 48px;
  top: 22px;
  max-width: 610px;
  font-size: 14px;
  line-height: 140%;
  color: #051c2c;
`
export const Bar = styled.div`
  position: static;
  height: 1px;
  left: 0px;
  top: 242px;
  background: #ececea;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
`
