enum BREAKPOINTS {
  SMALL = "440",
  MEDIUM = "720",
  LARGE = "1200",
}

export const breakpointQueries = {
  small: `(max-width: ${BREAKPOINTS.SMALL}px)`,
  medium: `(max-width: ${BREAKPOINTS.MEDIUM}px)`,
  large: `(max-width: ${BREAKPOINTS.LARGE}px)`,
}

export const media = {
  small: `@media ${breakpointQueries.small}`,
  medium: `@media ${breakpointQueries.medium}`,
  large: `@media ${breakpointQueries.large}`,
}
