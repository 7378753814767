import React from "react"
import { Container } from "./Text.styles"

export enum TEXT_ALIGN {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum TEXT_WEIGHT {
  NORMAL = "normal",
  SEMI_BOLD = "semi-bold",
  BOLD = "bold",
}

export interface TextProps {
  align?: TEXT_ALIGN.LEFT | TEXT_ALIGN.CENTER | TEXT_ALIGN.RIGHT
  children: React.ReactNode
  className?: string
  color?: string
  italic?: boolean
  size?: number
  uppercase?: boolean
  weight?: TEXT_WEIGHT.NORMAL | TEXT_WEIGHT.SEMI_BOLD | TEXT_WEIGHT.BOLD
}

const Text = ({
  align = TEXT_ALIGN.LEFT,
  children,
  className,
  color,
  italic = false,
  size = 16,
  uppercase,
  weight = TEXT_WEIGHT.NORMAL,
}: TextProps) => {
  return (
    <Container
      className={className}
      italic={italic}
      align={align}
      weight={weight}
      size={size}
      uppercase={uppercase}
      color={color}
    >
      {children}
    </Container>
  )
}

export default Text
