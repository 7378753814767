import React, { Dispatch, SetStateAction } from "react"
import { supportURL } from "../../../utils/constants"
import { TEXT_ALIGN, TEXT_WEIGHT } from "../../Text/Text"
import { FORM_STATE } from "../ActivateForm"
import {
  TextDescription,
  TextHeaderError,
  ButtonTryAgain,
  ExclamationStyled,
} from "../ActivateForm.styles"

interface AlreadyActivatedProps {
  setCurrentFormState: Dispatch<SetStateAction<FORM_STATE>>
}

const AlreadyActivated = ({ setCurrentFormState }: AlreadyActivatedProps) => {
  const setFormStep = () => {
    setCurrentFormState(FORM_STATE.FORM)
  }

  return (
    <>
      <ExclamationStyled />
      <TextHeaderError
        weight={TEXT_WEIGHT.BOLD}
        size={20}
        align={TEXT_ALIGN.CENTER}
        uppercase
        color="#051C2C"
      >
        Already activated
      </TextHeaderError>
      <TextDescription size={14} align={TEXT_ALIGN.CENTER} color="#697780">
        It appears that this membership has already been activated. Please check
        that the Order Number is correct.
      </TextDescription>
      <ButtonTryAgain onClick={setFormStep}>Try Again</ButtonTryAgain>
      <TextDescription size={14} align={TEXT_ALIGN.CENTER} color="#697780">
        Need some help? <a href={supportURL}>Contact Support</a>
      </TextDescription>
    </>
  )
}

export default AlreadyActivated
