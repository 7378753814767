import styled from "styled-components"
import Text from "../Text"
import Button from "../Button"
import Box from "../Box"
import Exclamation from "../../assets/icons/exclamation.inline.svg"
import { media } from "../../utils/media"
import formBackground from "../../assets/background-form.png"
import higFormBackground from "../../assets/hig/hig-background-form.png"


export const OxefitBackground = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-image: url(${formBackground});
  background-size: cover;
`

export const HigBackground = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-image: url(${higFormBackground});
  background-size: cover;
`

export const Container = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  padding: 32px;
  box-sizing: border-box;
  min-height: 100%;
  place-content: flex-end;

  ${media.medium} {
    padding: 0;
    flex-direction: column;
    height: 100%;
    place-content: flex-start;
  }
`

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  padding: 0px 104px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.medium} {
    padding: 0;
  }
`

export const TextSupport = styled(Text)`
  padding-top: 10px;
`

export const TextHeader = styled(Text)`
  margin: 124px 0 24px 0;
  font-family: "Resolve Sans Regular WD";

  ${media.medium} {
    margin: 60px 0 24px 0;
  }
`

export const TextHeaderError = styled(Text)`
  margin: 52px 0 24px 0;
  font-family: "Resolve Sans Regular WD";
`

export const TextList = styled(Text)`
  align-self: flex-start;
`

export const TextDescription = styled(Text)`
  margin-bottom: 28px;
`

export const ButtonStyled = styled(Button)`
  margin-top: 52px;
  align-self: center;
`

export const BoxStyled = styled(Box)`
  margin: 12px 0 40px 0;
`

export const ButtonTryAgain = styled(Button)`
  margin: 12px 0 40px 0;
`

export const ExclamationStyled = styled(Exclamation)`
  margin-top: 120px;

  ${media.medium} {
    margin-top: 60px;
  }
`

export const MobileTopBar = styled.div`
  display: none;
  ${media.medium} {
    transform: rotate(180deg);
    display: flex;
    padding: 60px 0 30px 0;
    width: 100vw;
    align-items: center;
    justify-content: center;
  }
`
