import { IActivateOrderData } from "./schema-types"

export enum MEMBERSHIP_STATUS {
  ACTIVATED = "Activated",
  UNSENT = "Unsent",
  INVALID = "Invalid",
  PENDING = "Pending",
}

export enum MEMBERSHIP_TYPE {
  POWER = "Power",
  PULSE = "Pulse",
  PINNACLE = "Pinnacle",
  HIG = "Hig",
  ERROR = "Error",
}

/**
 * Sku is a better determiner for Membership Type.
 *
 * Current Skus are :
 *
 * MEM-PIN-1
 * MEM-PIN-2
 * MEM-PIN-3
 * MEM-PIN-4
 * MEM-PIN-MO
 * MEM-PUL-1
 * MEM-PUL-2
 * MEM-PUL-3
 * MEM-PUL-4
 * MEM-PUL-MO
 * MEM-PWR-1
 * MEM-PWR-2
 * MEM-PWR-3
 * MEM-PWR-4
 * MEM-PWR-MO
 *
 * MEM-HIG-1
 * MEM-HIG-MO
 */

export const isMembershipSku = (sku: string) => sku.indexOf("MEM") === 0

export const membershipSkuToType = (sku: string): MEMBERSHIP_TYPE => {
  if (!isMembershipSku(sku)) {
    console.error(`No membership sku found for ${sku}`)
    return MEMBERSHIP_TYPE.ERROR
  } else if (sku.indexOf("PIN") > 0) {
    return MEMBERSHIP_TYPE.PINNACLE
  } else if (sku.indexOf("PWR") > 0) {
    return MEMBERSHIP_TYPE.POWER
  } else if (sku.indexOf("PUL") > 0) {
    return MEMBERSHIP_TYPE.PULSE
  } else if (sku.includes("HIG")) {
    return MEMBERSHIP_TYPE.HIG
  } else {
    console.error(`No membership sku found for ${sku}`)
    return MEMBERSHIP_TYPE.ERROR
  }
}

export const orderSkuToMembershipType = (
  orderData: IActivateOrderData
): MEMBERSHIP_TYPE => {
  if (orderData && orderData.membership_sku) {
    return membershipSkuToType(orderData.membership_sku)
  } else {
    return MEMBERSHIP_TYPE.ERROR
  }
}
