import styled from "styled-components"
import { media } from "../../utils/media"
import Text from "../Text"
// import headerBackground from "../../assets/Activated-background.svg"

import PinnacleBackground from "../../assets/membership-pinnacle-bg.png"
import PulseBackground from "../../assets/membership-pulse-bg.png"
import PowerBackground from "../../assets/membership-power-bg.png"
import HigBackground from "../../assets/hig/hig-background-form.png"

import OFLogo from "../../assets/Frame.inline.svg"
import Phone from "../../assets/Activated-phone.png"
import OxefitQr from "../../assets/Activated-qr-code.svg"
import HigQr from "../../assets/hig/Activated-qr-code.svg"
import Line from "../../assets/Line.svg"
import GooglePlay from "../../assets/google-play-badge.svg"
import AppStore from "../../assets/app-store-badge.svg"
import Support from "../../assets/icons/Activated-support.svg"
import FAQ from "../../assets/icons/Activated-FAQ.svg"

export const ViewHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0px;
  position: absolute;
  width: 100%;
  top: 0px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(14px);

  ${media.large} {
    background: none;
    backdrop-filter: none;
  }
`
export const HeaderBackground = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("");
  background-position: center;
  height: 480px;
  width: 100%;
  overflow: hidden;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.medium} {
    height: 440px;
  }
  ${media.small} {
    height: 320px;
  }
`

export const HeaderBackgroundPinnacle = styled(HeaderBackground)`
  background-image: url(${PinnacleBackground});
  background-position-y: 8%;
`
export const HeaderBackgroundPower = styled(HeaderBackground)`
  background-image: url(${PowerBackground});
  background-position-y: 10%;
`

export const HeaderBackgroundHig = styled(HeaderBackground)`
  background-image: url(${HigBackground});
  background-position-y: 10%;
`

export const HeaderBackgroundPulse = styled(HeaderBackground)`
  background-image: url(${PulseBackground});
  background-position-y: 20%;
`
export const CardContainer = styled.div`
  align-self: center;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  position: relative;

  ${media.small} {
    margin: 0;
  }
`
export const TextSmall = styled(Text)`
  max-width: 550px;
  line-height: 140%;
  margin: 30px 10px 0 10px;
  margin: 16px 0;
  align-self: center;
  margin-right: 5px;
  margin-left: 5px;
`
export const TextServices = styled(TextSmall)`
  font-weight: 600;
`
export const TextBig = styled(Text)`
  font-family: "helvetica";
  line-height: 100%;
  letter-spacing: -0.01em;
  margin: 16px 0;

  ${media.small} {
    font-size: 20px;
  }
`
export const LinearGradient = styled.div`
  background: linear-gradient(
    180deg,
    #567abb -1.04%,
    #53a3da 51.52%,
    #4cc3cb 100%
  );
  height: 60px;
  width: 60px;
  align-items: stretch;
  border-radius: 12px;
  align-self: center;
  margin-top: 60px;
`
export const Card = styled.div`
  border-radius: 12px;
  box-shadow: 0px 10px 37px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  /* max-height: 348px; */
  padding: 40px;
  display: flex;
  flex-direction: row;
  margin-top: 60px;

  ${media.small} {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
`
export const XIcon = styled(OFLogo)`
  height: 60px;
  width: 60px;
  margin-top: 60px;
  margin-bottom: 20px;
  align-self: center;

  ${media.small} {
    margin-top: 20px;
  }
`
export const Subtitle = styled(Text)`
  line-height: 100%;
  letter-spacing: -0.01em;
  margin: 16px 0;

  ${media.small} {
    font-size: 16px;
  }
`
export const Title = styled(Text)`
  font-family: "Resolve Sans Regular WD";
  line-height: 100%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  margin: 24px 0;
  color: white;
  text-align: center;

  span.break {
    display: inline-block;
    ${media.medium} {
      display: block;
    }
  }

  ${media.medium} {
    /* font-size: 24px; */
    margin: 16px 0;
  }

  ${media.small} {
    font-size: 24px;
  }
`

export const TitleAlpha = styled(Text)`
  font-family: "Resolve Sans Regular WD";
  position: relative;
  color: white;
  text-transform: uppercase;
  opacity: 0.15;
  transform: translate(0%, -100%);
  font-size: 180px;
  font-weight: bold;

  ${media.medium} {
    font-size: 160px;
    transform: translate(0%, -150%);
  }

  ${media.small} {
    font-size: 100px;
    transform: translate(0%, -150%);
  }
`

export const CardContent = styled.div`
  max-width: 280px;
  height: 100%;
  display: flex;
  width: 320px;
  flex-direction: column;
  align-items: center;
`
export const PhoneContent = styled(CardContent)`
  max-width: 360px;
  width: 360px;
  ${media.medium} {
    display: none;
  }
`

export const Cardlines = styled.div`
  width: 270px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
export const OxefitQrImg = styled.div`
  background-image: url(${OxefitQr});
  height: 200px;
  width: 200px;
  margin-top: 40px;
  margin-bottom: 30px;

  ${media.small} {
    display: none;
  }
`
export const HigQrImg = styled.div`
  background-image: url(${HigQr});
  background-size: 200px;
  height: 200px;
  width: 200px;
  margin-top: 40px;
  margin-bottom: 30px;

  ${media.small} {
    display: none;
  }
`
export const PhoneImg = styled.div`
  background-image: url(${Phone});
  height: 538px;
  top: 10px;
  right: 370px;
  width: 324px;
  position: absolute;
  ${media.medium} {
    display: none;
  }
`
export const LineImg = styled.div`
  background-image: url(${Line});
  height: 1px;
  width: 120px;
  margin-right: 8px;
  margin-left: 8px;

  ${media.small} {
    display: none;
  }
`
export const AppStoreImg = styled.a`
  background-image: url(${AppStore});
  height: 40px;
  width: 120px;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-right: 7px;
`
export const GooglePlayImg = styled.a`
  background-image: url(${GooglePlay});
  height: 40px;
  width: 137px;
  margin-top: 10px;
  margin-left: 7px;
  margin-bottom: 30px;
`
export const Service = styled.div`
  max-height: 428px;
  padding: 20px;
  gap: 60px;
  display: flex;
  margin-top: 50px;
  flex-direction: row;
  align-items: center;
  max-width: 688px;
  justify-content: center;
  ${media.medium} {
    flex-wrap: wrap;
  }
  ${media.small} {
    max-height: 470px;
    box-shadow: none;
    padding: 0;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`
export const FAQIcon = styled.div`
  background-image: url(${FAQ});
  height: 26px;
  width: 26px;
  align-self: center;
`
export const SupportIcon = styled.div`
  background-image: url(${Support});
  height: 29px;
  width: 29px;
  align-self: center;
`
