import React, { useEffect, useState } from "react"
import { StringParam, useQueryParam } from "use-query-params"
import Activation from "../../components/Activation"
import ActivateForm from "../../components/ActivateForm"
import Layout from "../../components/Layout/Layout.component"
import ErrorHandler from "../../components/ErrorHandler"
import { PageMeta } from "../../components/PageMeta/PageMeta"

const ActivatePage = () => {
  const [isTokenChecked, setIsTokenChecked] = useState(false)
  const [token] = useQueryParam("token", StringParam)
  const [queryParam, setQueryParam] = useState<String | null>(null)
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      setQueryParam(urlParams.get("token"))
      setIsTokenChecked(true)
    }
  }, [])
  if (!isTokenChecked) return null
  else if (queryParam && token)
    return (
      <PageMeta>
        <Layout>
          <ErrorHandler>
            <Activation />
          </ErrorHandler>
        </Layout>
      </PageMeta>
    )
  else if (!queryParam && !token)
    return (
      <PageMeta>
        <Layout>
          <ActivateForm />
        </Layout>
      </PageMeta>
    )
}
export default ActivatePage
