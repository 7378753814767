import ActivateLayout from "../../ActivateLayout"
import React, { Dispatch, SetStateAction } from "react"
import axios from "axios"
import { HIG_MEMBERSHIP_ACTIVATED_REDIRECT_URL, URL_ACTIVATE_SIGN_OUT, URL_ACTIVATION } from "../../../utils/api"
import { StringParam, useQueryParam } from "use-query-params"
import { MEMBERSHIP_STATUS } from "../../../utils/membership"
import { IActivateOrderData } from "../../../utils/schema-types"
import { useError } from "../../ErrorHandler/ErrorHandler"
import { isHigPage } from "../../../utils/shopify";

interface NotActivatedProps {
  setCurrentActivationState: Dispatch<SetStateAction<MEMBERSHIP_STATUS>>
  orderData: IActivateOrderData
}

const Activate = ({
  setCurrentActivationState,
  orderData,
}: NotActivatedProps) => {
  const [token] = useQueryParam("token", StringParam)
  const errorHandler = useError()
  const handleActivateMembership = () => {
    return axios
      .post(URL_ACTIVATION + "?token=" + token, {}, { withCredentials: true })
      .then(() => {
        if (isHigPage()) window.location.replace(HIG_MEMBERSHIP_ACTIVATED_REDIRECT_URL)
        else setCurrentActivationState(MEMBERSHIP_STATUS.ACTIVATED)
      })
      .catch(error => {
        errorHandler.setErrorMessage("Failed to activate membership.")
        console.log(error)
      })
  }

  const autoActivateMembership = () => {
     return handleActivateMembership()
  }

  const handleSwitchAccounts = () => {
    axios
      .get(URL_ACTIVATE_SIGN_OUT + "?token=" + token, { withCredentials: true })
      .catch(error => {
        console.log(error)
      })
  }
  return (
    <ActivateLayout
      orderData={orderData}
      handleActivateMembership={handleActivateMembership}
      autoActivateMembership={autoActivateMembership}
      handleSwitchAccounts={handleSwitchAccounts}
    />
  )
}
export default Activate
