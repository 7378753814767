import React, { Dispatch, SetStateAction } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import FormInput from "../../FormInput"
import { TEXT_ALIGN, TEXT_WEIGHT } from "../../Text/Text"
import {
  ButtonStyled,
  TextDescription,
  TextHeader,
  TextSupport,
  FormStyled,
} from "../ActivateForm.styles"
import { FORM_STATE } from "../ActivateForm"
import { emailRegex, supportURL } from "../../../utils/constants"
import { MEMBERSHIP_STATUS } from "../../../utils/membership"
import { URL_ORDER_DETAILS } from "../../../utils/api"
import { OrderDataProps } from "./OrderLocated"
import { isHigPage } from "../../../utils/shopify";

interface FormProps {
  setCurrentFormState: Dispatch<SetStateAction<FORM_STATE>>
  setOrderData: Dispatch<SetStateAction<OrderDataProps>>
}

const Form = ({ setCurrentFormState, setOrderData }: FormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const setNextStep = (data: any) => {
    const { email, order_id } = data
    if (errors.email || errors.order_id) {
      return null
    } else {
      axios
        .get(URL_ORDER_DETAILS, {
          params: {
            order_id,
            email,
          },
          withCredentials: true,
        })
        .then(response => {
          const { activation_status } = response.data
          if (activation_status === MEMBERSHIP_STATUS.ACTIVATED) {
            setCurrentFormState(FORM_STATE.ALREADY_ACTIVATED)
          } else {
            setOrderData(response.data)
            setCurrentFormState(FORM_STATE.ORDER_LOCATED)
          }
        })
        .catch(error => {
          console.log(error)
          setCurrentFormState(FORM_STATE.ORDER_NOT_FOUND)
        })
    }
  }

  const shop = isHigPage() ? "HIG": "oxefit";

  return (
    <>
      <FormStyled onSubmit={handleSubmit(setNextStep)}>
        <TextHeader
          weight={TEXT_WEIGHT.BOLD}
          size={20}
          align={TEXT_ALIGN.CENTER}
          uppercase
          color="#051C2C"
        >
          Activate an {shop} membership
        </TextHeader>
        <TextDescription size={14} align={TEXT_ALIGN.CENTER} color="#697780">
          After entering the information below, you’ll be asked to create an
          account or sign in to activate your membership.
        </TextDescription>
        <FormInput
          label={"Email Address"}
          id={"email"}
          register={register}
          required
          pattern={emailRegex}
          patternMessage="Please enter a valid email"
          errorMessage={errors?.email?.message}
        />
        <FormInput
          label={"Order Number (e.g. OXES1234)"}
          id={"order_id"}
          register={register}
          required
          errorMessage={errors?.order_id?.message}
        />
        <TextSupport size={12} color="#374956">
          Can’t find the Order Number or Email Address? Please check the Order
          Confirmation or <a href={supportURL}>Contact Support</a>.
        </TextSupport>
        <ButtonStyled>Continue</ButtonStyled>
      </FormStyled>
    </>
  )
}

export default Form
