import React from "react"
import OrderItem from "../../OrderItem/OrderItem"
import { TEXT_ALIGN, TEXT_WEIGHT } from "../../Text/Text"
import {
  TextDescription,
  TextHeader,
  BoxStyled,
  TextList,
  ButtonStyled,
} from "../ActivateForm.styles"
import { redirect } from "../../../utils/common";

export interface OrderDataProps {
  accessory_color: string
  accessory_package: string
  accessory_title: string
  activation_token: string
  membership_package: string
  membership_term: string
  membership_title: string
  shopify_order_id: string
  shopify_order_name: string
}
interface OrderLocatedProps {
  orderData: OrderDataProps
}

const OrderLocated = ({ orderData }: OrderLocatedProps) => {
  const {
    accessory_color,
    accessory_package,
    accessory_title,
    membership_package,
    membership_term,
    membership_title,
    shopify_order_name,
    activation_token,
  } = orderData
  const handleAuthorization = () => {
      redirect(activation_token)
  }
  return (
    <>
      <TextHeader
        weight={TEXT_WEIGHT.BOLD}
        size={20}
        align={TEXT_ALIGN.CENTER}
        uppercase
        color="#051C2C"
      >
        Order located
      </TextHeader>
      <TextDescription size={14} align={TEXT_ALIGN.CENTER} color="#697780">
        If the order details below are correct, please proceed to activate the
        membership.
      </TextDescription>
      <BoxStyled title="Order Number" value={shopify_order_name} />
      <TextList weight={TEXT_WEIGHT.SEMI_BOLD} size={16} color="#697780">
        Here are the goods:
      </TextList>
      <OrderItem
        name={accessory_title}
        description={`${accessory_color}, ${accessory_package}`}
      />
      <OrderItem
        name={membership_title}
        description={`${membership_term}, ${membership_package}`}
      />
      <ButtonStyled onClick={handleAuthorization}>
        Activate Membership
      </ButtonStyled>
    </>
  )
}

export default OrderLocated
