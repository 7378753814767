import React from "react"
import { Container, Title, Value } from "./Box.styles"

interface BoxProps {
  title?: string
  value?: string
  className?: string
}

const Box = ({ title, value, className }: BoxProps) => {
  return (
    <Container className={className}>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Container>
  )
}

export default Box
