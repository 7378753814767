import styled from "styled-components"

export const Container = styled.div`
  border-bottom: 0.5px solid rgba(24, 22, 20, 0.2);
  display: flex;
  flex-direction: column;
  width: 320px;
  margin-top: 16px;
  padding: 8px 0;
  gap: 5px;
`
export const Name = styled.div``
export const Description = styled.div``
