import React, { Dispatch, SetStateAction } from "react"
import { supportURL } from "../../../utils/constants"
import { TEXT_ALIGN, TEXT_WEIGHT } from "../../Text/Text"
import { FORM_STATE } from "../ActivateForm"
import {
  TextDescription,
  TextHeaderError,
  ButtonTryAgain,
  ExclamationStyled,
} from "../ActivateForm.styles"

interface OrderNotFoundProps {
  setCurrentFormState: Dispatch<SetStateAction<FORM_STATE>>
}

const OrderNotFound = ({ setCurrentFormState }: OrderNotFoundProps) => {
  const setFormStep = () => {
    setCurrentFormState(FORM_STATE.FORM)
  }
  return (
    <>
      <ExclamationStyled />
      <TextHeaderError
        weight={TEXT_WEIGHT.BOLD}
        size={20}
        align={TEXT_ALIGN.CENTER}
        uppercase
        color="#051C2C"
      >
        Order not found
      </TextHeaderError>
      <TextDescription size={14} align={TEXT_ALIGN.CENTER} color="#697780">
        We’re sorry, we could not find an order matching the Email Address and
        Order Number that you entered.
      </TextDescription>
      <ButtonTryAgain onClick={setFormStep}>Try Again</ButtonTryAgain>
      <TextDescription size={14} align={TEXT_ALIGN.CENTER} color="#697780">
        Still can’t find your order? <a href={supportURL}>Contact Support</a>
      </TextDescription>
    </>
  )
}

export default OrderNotFound
