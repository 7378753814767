import React, { FC } from "react"
import { Helmet } from "react-helmet"

export const PageMeta: FC<any> = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>OxeFit Activation</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
      </Helmet>
      {children}
    </>
  )
}
