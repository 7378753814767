import React, {useCallback, useEffect, useState} from "react"
import ListItem from "../ListItem"
import { TEXT_ALIGN } from "../Text/Text"
import {
  PowerActivityTitle,
  PinnacleActivityTitle,
  PulseActivityTitle,
  Activities,
  ActivityDescription,
  PinnacleBackgroundImage,
  PowerBackgroundImage,
  PulseBackgroundImage,
  ButtonWhite,
  ButtonBlue,
  Card,
  CardContainer,
  TextSwitchAccount,
  LinearGradient,
  OxefitLogo,
  RadialGradient,
  TextBig,
  TextEmail,
  TextDescription,
  TextMemHeader,
  TermsAndConditions,
  XLogo,
  View,
  ViewHeader,
  HigBackgroundImage,
} from "./ActivateLayout.styles"
import TickIcon from "../../assets/icons/tick.inline.svg"
import PinnacleActivities from "../../assets/icons/PinnacleActivities.svg"
import PulseActivities from "../../assets/icons/PulseActivities.svg"
import PowerActivities from "../../assets/icons/PowerActivities.svg"
import { privacyURL, termsURL } from "../../utils/constants"
import { IActivateOrderData } from "../../utils/schema-types"
import {
  MEMBERSHIP_TYPE,
  orderSkuToMembershipType,
} from "../../utils/membership"
import { Spinner } from "./Spinner"
import { isHigPage } from "../../utils/shopify";
import Logo from "../../assets/icons/oxefitLogo.inline.svg";

interface ActivateProps {
  orderData?: IActivateOrderData
  handleActivateMembership: () => Promise<void>
  autoActivateMembership: () => Promise<void>
  handleSwitchAccounts: () => void
}

interface MembershipListItem {
  icon: any
  description: string
  bar: boolean
  membership_specific?: Record<MEMBERSHIP_TYPE, string>
}

const Activate = ({
  orderData,
  handleActivateMembership,
  autoActivateMembership,
  handleSwitchAccounts,
}: ActivateProps) => {
  const membershipType = orderSkuToMembershipType(orderData!)

  const [activating, setActivating] = useState(false)

  const onActivateClick = useCallback(() => {
    setActivating(true)
    handleActivateMembership().catch(() => setActivating(false))
  }, [])

  const BUTTON_TEXT = activating ? "Activating" : "Retry Activation"

  const ListItems: MembershipListItem[] = [
    {
      icon: TickIcon,
      description: "Perform 250+ strength training exercises on the XS1",
      bar: true,
    },
    {
      icon: TickIcon,
      description: "Perform 300+ strength training exercises on the XP1",
      bar: true,
    },
    {
      icon: TickIcon,
      description: "",
      membership_specific: {
        [MEMBERSHIP_TYPE.POWER]:
          "Access trainer-led classes, workouts and programs for Strength Training and SkiCross on the XS1",
        [MEMBERSHIP_TYPE.PULSE]:
          "Access trainer-led classes, workouts and programs for Strength Training, SkiCross, Rowing, Kayaking, Canoeing, Paddleboarding and SurfSwim on the XS1",
        [MEMBERSHIP_TYPE.PINNACLE]:
          "Access trainer-led classes, workouts and programs for Strength Training, SkiCross, Rowing, Kayaking, Canoeing, Paddleboarding, SurfSwim and Digital Pilates on the XS1",
        [MEMBERSHIP_TYPE.ERROR]:
          "Access trainer-led classes, workouts and programs for Strength Training and SkiCross on the XS1",
        [MEMBERSHIP_TYPE.HIG]:
            // todo: maybe change for HIG specific content
            "Access trainer-led classes, workouts and programs on the XS1",
      },
      bar: true,
    },
    {
      icon: TickIcon,
      description: "Unlock advanced training modes for Strength Training",
      bar: true,
    },
    {
      icon: TickIcon,
      description:
        "Track your data and plan your workouts using the XS1 and OxeFit app",
      bar: false,
    },
  ]

  const renderItemsList = (list: MembershipListItem[]) => {
    const itemsList = list.map((item, key) => (
      <ListItem
        key={`list-item-${key}`}
        icon={item.icon}
        description={
          item.membership_specific
            ? item.membership_specific[membershipType]
            : item.description
        }
        bar={item.bar}
      />
    ))
    return itemsList
  }

  const renderTitle = () => {
    switch (membershipType) {
      case MEMBERSHIP_TYPE.POWER: {
        return <PowerActivityTitle uppercase>Power</PowerActivityTitle>
      }
      case MEMBERSHIP_TYPE.PULSE: {
        return <PulseActivityTitle uppercase>Pulse</PulseActivityTitle>
      }
      case MEMBERSHIP_TYPE.PINNACLE: {
        return <PinnacleActivityTitle uppercase>Pinnacle</PinnacleActivityTitle>
      }
      case MEMBERSHIP_TYPE.HIG: {
        return <PowerActivityTitle uppercase>Hit IT Great</PowerActivityTitle>
      }
    }
  }
  const renderActivityDescription = () => {
    switch (membershipType) {
      case MEMBERSHIP_TYPE.POWER: {
        return "Strength & SkiCross"
      }
      case MEMBERSHIP_TYPE.PULSE: {
        return "Strength, SkiCross & HydroFit"
      }
      case MEMBERSHIP_TYPE.PINNACLE: {
        return "Strength, SkiCross, HydroFit & Pilates"
      }
      case MEMBERSHIP_TYPE.HIG: {
        // todo: maybe change for HIG specific content
        return ""
      }
    }
  }
  const renderActivities = () => {
    switch (membershipType) {
      case MEMBERSHIP_TYPE.POWER: {
        return <Activities src={PowerActivities} />
      }
      case MEMBERSHIP_TYPE.PULSE: {
        return <Activities src={PulseActivities} />
      }
      case MEMBERSHIP_TYPE.PINNACLE: {
        return <Activities src={PinnacleActivities} />
      }
      case MEMBERSHIP_TYPE.HIG: {
        // todo: maybe change for HIG specific content
        // just display all activities
        return <Activities src={PinnacleActivities} />
      }
    }
  }

  const renderBackgroundImg = () => {
    const content = (
      <>
        <XLogo />
        {renderTitle()}
        <ActivityDescription color="#c2c2be">
          {renderActivityDescription()}
        </ActivityDescription>
        {renderActivities()}
      </>
    )
    switch (membershipType) {
      case MEMBERSHIP_TYPE.POWER: {
        return <PowerBackgroundImage>{content}</PowerBackgroundImage>
      }
      case MEMBERSHIP_TYPE.PULSE: {
        return <PulseBackgroundImage>{content}</PulseBackgroundImage>
      }
      case MEMBERSHIP_TYPE.PINNACLE: {
        return <PinnacleBackgroundImage>{content}</PinnacleBackgroundImage>
      }
      case MEMBERSHIP_TYPE.HIG:
        return <HigBackgroundImage>{content}</HigBackgroundImage>
    }
  }

  const renderMembershipMessage = (orderData: IActivateOrderData) => {
    const { oxefit_user_email } = orderData
    //Your membership will be linked to the user account: <username>

    return (
      <>
        <TextDescription size={16} color="#ffffff" align={TEXT_ALIGN.CENTER}>
          Your membership will be linked to the user account:
        </TextDescription>
        <TextEmail size={16} color="#ffffff" align={TEXT_ALIGN.CENTER}>
          {oxefit_user_email || "Not Found"}
        </TextEmail>
      </>
    )

    /** Previous conditional messaging */
    // if (oxefit_user_email === shopify_user_email) {
    //   return (
    //     <>
    //       <TextDescription size={16} color="#ffffff" align={TEXT_ALIGN.CENTER}>
    //         Your membership will be linked to the OxeFit account that you use
    //         with
    //       </TextDescription>
    //       <TextEmail size={16} color="#ffffff" align={TEXT_ALIGN.CENTER}>
    //         {oxefit_user_email || "ERROR"}
    //       </TextEmail>
    //     </>
    //   )
    // } else if (oxefit_user_email !== shopify_user_email) {
    //   return (
    //     <>
    //       <TextDescription size={16} color="#ffffff" align={TEXT_ALIGN.CENTER}>
    //         Please note, the email you have chosen to associate with your
    //         membership, <b>{oxefit_user_email || "ERROR"}</b>, is different from
    //         the email address associated with your order.{" "}
    //         <b>( {shopify_user_email} </b>)
    //       </TextDescription>
    //     </>
    //   )
    // }
  }

  useEffect(() => {
    setActivating(true)
    autoActivateMembership().catch(() => setActivating(false))
  }, []);

  const logo = isHigPage() ? null : <Logo />

  return (
    <View>
      <ViewHeader>
        {logo}
      </ViewHeader>
      <LinearGradient>
        <RadialGradient>
          <TextBig
            size={34}
            uppercase={true}
            color="#ffffff"
            align={TEXT_ALIGN.CENTER}
          >
            Activate your <br />
            membership now?
          </TextBig>
          {renderMembershipMessage(orderData!)}

          {/* <TextSwitchAccount
            size={16}
            color="#ffffff"
            align={TEXT_ALIGN.CENTER}
          >
            Want to use a different account?{" "}
            <a onClick={handleSwitchAccounts}>Switch accounts</a>
          </TextSwitchAccount> */}
          <ButtonWhite enabled={!activating} onClick={onActivateClick}>
            <Spinner enabled={activating} color="blue" />
            {BUTTON_TEXT}
          </ButtonWhite>
        </RadialGradient>
      </LinearGradient>

      <CardContainer>
        <TextMemHeader color="#051C2C">Membership Details</TextMemHeader>
        {renderBackgroundImg()}
        <Card>
          <TextBig size={20} color="#697780" uppercase={true}>
            what's included
          </TextBig>
          {renderItemsList(ListItems)}
          <ButtonBlue enabled={!activating} onClick={onActivateClick}>
            <Spinner enabled={activating} color="white" />
            {BUTTON_TEXT}
          </ButtonBlue>
        </Card>
        <TermsAndConditions size={12} color="#051c2c" align={TEXT_ALIGN.CENTER}>
          At the end of your membership period, you can renew your membership or
          choose another membership type, at which point an additional monthly
          cost will apply. By activating your membership, you agree to OxeFit’s{" "}
          <a href={termsURL} target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href={privacyURL} target="_blank">
            Privacy Policy
          </a>
          .
        </TermsAndConditions>
      </CardContainer>
    </View>
  )
}

export default Activate
