import styled from "styled-components"
import { TEXT_ALIGN, TEXT_WEIGHT } from "./Text"

type TextStyleProps = {
  align: TEXT_ALIGN.LEFT | TEXT_ALIGN.CENTER | TEXT_ALIGN.RIGHT
  italic?: boolean
  size: number
  uppercase?: boolean
  weight: TEXT_WEIGHT.NORMAL | TEXT_WEIGHT.SEMI_BOLD | TEXT_WEIGHT.BOLD
}

export const Container = styled.div<TextStyleProps>`
  font-family: "Resolve Sans";
  color: ${props => (props.color ? props.color : "unset")};
  text-align: ${props => props.align};
  font-weight: ${props =>
    props.weight === TEXT_WEIGHT.SEMI_BOLD ? 600 : props.weight};
  font-size: ${props => `${props.size}px`};
  text-transform: ${props => (props.uppercase ? "uppercase" : "none")};
  font-style: ${props => (props.italic ? "italic" : "normal")};
`
