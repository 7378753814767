import React from "react"
import Text from "../Text"
import { Container, Name, Description } from "./OrderItem.styles"

interface BoxProps {
  name?: string
  description?: string
  className?: string
}

const OrderItem = ({ name, description, className }: BoxProps) => {
  return (
    <Container className={className}>
      <Text size={12} color="#697780">
        {name}
      </Text>
      <Text size={12} color="#697780" italic>
        {description}
      </Text>
    </Container>
  )
}

export default OrderItem
