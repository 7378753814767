import styled from "styled-components"
import { media } from "../../utils/media"

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 32px;
  max-width: 528px;
  border-radius: 12px;
  box-shadow: 0px 10px 37px rgba(0, 0, 0, 0.15);

  ${media.medium} {
    padding: 40px 27px;
    max-width: 100vw;
    border-radius: 0;
    height: 100%;
  }
`
