import React from "react"
import { ProfitBox, ProfitText, Bar } from "./ListItem.styles"

interface ListItemProps {
  description?: string
  className?: string
  icon?: string
  bar?: boolean
}

const ListItem = ({ icon, description, className, bar }: ListItemProps) => {
  return (
    <>
      <ProfitBox className={className}>
         <div>
             {icon && React.createElement(icon)}
         </div>
        <ProfitText>{description}</ProfitText>
      </ProfitBox>
      {bar && <Bar />}
    </>
  )
}

export default ListItem
