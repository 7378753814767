import React, { FC, createContext, useContext, useMemo, useState } from "react"
import { ErrorFill, ErrorHeader } from "./ErrorHandler.styles"
import Text from "../Text"
import { TEXT_ALIGN } from "../Text/Text"
import { ErrorBoundary } from "./ErrorBoundary"

interface IErrorContextValue {
  setErrorMessage: (message: string) => void
}

export const ErrorContext = createContext<IErrorContextValue>(null!)

export const useError = () => useContext(ErrorContext)

const ErrorHandlerWithBoundary: FC<any> = props => {
  return (
    <ErrorBoundary>
      <ErrorHandler>{props.children}</ErrorHandler>
    </ErrorBoundary>
  )
}

const ErrorHandler: FC<any> = props => {
  const { children } = props

  const [errorState, setErrorState] = useState(() => ({
    message: "Your membership could not be activated.",
    hasError: false,
  }))

  const providerValue = useMemo<IErrorContextValue>(() => {
    return {
      setErrorMessage: (message: string) => {
        setErrorState({
          hasError: true,
          message,
        })
        throw new Error(message)
      },
    }
  }, [errorState])

  return (
    <ErrorContext.Provider value={providerValue}>
      {/* <Container> */}
      <ErrorHeader error={errorState.hasError}>
        <Text align={TEXT_ALIGN.CENTER} size={16}>
          {" "}
          Error: {errorState.message} Please{" "}
          <a href="http://support.oxefit.com/" target="_blank">
            contact Support
          </a>
        </Text>
      </ErrorHeader>
      <ErrorFill error={errorState.hasError} />
      {children}
      {errorState.hasError ? (<button
        onClick={() => setErrorState({ hasError: false, message: "" })}
        style={{
          position: "fixed",
          background: "none",
          border: "none",
          top: "0px",
          left: "0px",
          zIndex: 10000,
          cursor: "pointer",
          fontSize: "30px",
          color: "#3a4be6",
        }}
      >
        &times;
      </button>)
        : null
      }
    </ErrorContext.Provider>
  )
}

export default ErrorHandlerWithBoundary
