import React from "react"
import { Container, Input, Label, ErrorContainer } from "./FormInput.styles"
import { RegisterOptions, UseFormRegisterReturn } from "react-hook-form"

interface FormInputProps {
  errorMessage?: string
  id: string
  label?: string
  pattern?: RegExp
  patternMessage?: string
  register: (name: string, options?: RegisterOptions) => UseFormRegisterReturn
  required?: boolean
}

const FormInput = ({
  errorMessage,
  id,
  label,
  pattern,
  register,
  required = false,
  patternMessage,
  ...inputProps
}: FormInputProps) => {
  return (
    <Container>
      <Input
        {...inputProps}
        placeholder={" "}
        {...register(id, {
          ...(required && { required: "This field is required" }),
          ...(pattern &&
            patternMessage && {
              pattern: { value: pattern, message: patternMessage },
            }),
        })}
      />
      {label && <Label htmlFor={id}>{label}</Label>}
      {errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer>}
    </Container>
  )
}

export default FormInput
