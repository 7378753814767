import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Resolve Sans";
    src: url('/fonts/resolve-sans/resolve-sans-light.woff') format('woff'), url('/fonts/resolve-sans-light.woff2') format('woff2');
    font-weight: normal;
  }

  @font-face {
    font-family: "Resolve Sans";
    src: url('/fonts/resolve-sans/resolve-sans-semi-light.woff') format('woff'), url('/fonts/resolve-sans/resolve-sans-semi-light.woff2') format('woff2');
    font-weight: 600;
  }

  @font-face {
    font-family: "Resolve Sans";
    src: url('/fonts/resolve-sans/resolve-sans-regular.woff') format('woff'), url('/fonts/resolve-sans/resolve-sans-regular.woff2') format('woff2');
    font-weight: bold;
  }

  @font-face {
    font-family: "Resolve Sans Light WD";
    src: url('/fonts/resolve-sans/resolve-sans-light-wd.woff') format('woff'), url('/fonts/resolve-sans/resolve-sans-light-wd.woff2') format('woff2');
    font-weight: normal;
  }

  @font-face {
    font-family: "Resolve Sans Semi Light WD";
    src: url('/fonts/resolve-sans/resolve-sans-semi-light-wd.woff') format('woff'), url('/fonts/resolve-sans/resolve-sans-semi-light-wd.woff2') format('woff2');
    font-weight: 600;
  }

  @font-face {
    font-family: "Resolve Sans Regular WD";
    src: url('/fonts/resolve-sans/resolve-sans-regular-wd.woff') format('woff'), url('/fonts/resolve-sans/resolve-sans-regular-wd.woff2') format('woff2');
  }

  body {
   width: 100%;
   height: 100%;
   margin: 0;
   font-family: "Resolve Sans"
  }
`
