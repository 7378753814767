import React, { FC } from "react"
import styled from "styled-components"

const StyledSvg = styled.svg<{ enabled: boolean }>`
  margin-right: 12px;
  display: ${props => (props.enabled ? "block" : "none")};
`

export const Spinner: FC<{
  color?: "white" | "blue"
  enabled: boolean
}> = ({ color = "#772583", enabled = true }) => {
  const fill = color === "white" ? "white" : "#772583"
  const stroke = color === "white" ? "grad-white" : "grad-blue"

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38 38"
      width="20px"
      height="20px"
      enabled={enabled}
    >
      <defs>
        <linearGradient
          x1="8.042%"
          y1="0%"
          x2="65.682%"
          y2="23.865%"
          id="grad-blue"
        >
          <stop stopColor="#772583" stopOpacity="0" offset="0%" />
          <stop stopColor="#772583" stopOpacity="1" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="8.042%"
          y1="0%"
          x2="65.682%"
          y2="23.865%"
          id="grad-white"
        >
          <stop stopColor="#fff" stopOpacity="0" offset="0%" />
          <stop stopColor="#fff" stopOpacity="1" offset="100%" />
        </linearGradient>
      </defs>

      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <path
          d="M36 18c0-9.94-8.06-18-18-18"
          stroke={`url(#${stroke})`}
          strokeWidth="2"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </path>
        <circle fill={fill} cx="36" cy="18" r="1">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </StyledSvg>
  )
}
