import React, { useState } from "react"
import Modal from "../Modal"
import { Container, Content, HigBackground, OxefitBackground } from "./ActivateForm.styles"
import Form from "./states/Form"
import OrderLocated from "./states/OrderLocated"
import OrderNotFound from "./states/OrderNotFound"
import AlreadyActivated from "./states/AlreadyActivated"

import { StringParam, useQueryParam } from "use-query-params"
import { isHigPage } from "../../utils/shopify";
import { OxefitLogo, HigLogo } from "./Logo";

export enum FORM_STATE {
  FORM = "form",
  ORDER_LOCATED = "order_located",
  ORDER_NOT_FOUND = "order_not_found",
  ALREADY_ACTIVATED = "already_activated",
}

const ActivateForm = () => {
  const [currentFormState, setCurrentFormState] = useState(FORM_STATE.FORM)
  const [orderData, setOrderData] = useState({
    accessory_color: "",
    accessory_package: "",
    accessory_title: "",
    activation_token: "",
    membership_package: "",
    membership_term: "",
    membership_title: "",
    shopify_order_id: "",
  })
  const [token] = useQueryParam("token", StringParam)
  const renderCurrentState = () => {
    switch (currentFormState) {
      case FORM_STATE.FORM: {
        return (
          <Form
            setCurrentFormState={setCurrentFormState}
            setOrderData={setOrderData}
          />
        )
      }
      case FORM_STATE.ORDER_LOCATED: {
        return <OrderLocated orderData={orderData} />
      }
      case FORM_STATE.ORDER_NOT_FOUND: {
        return <OrderNotFound setCurrentFormState={setCurrentFormState} />
      }
      case FORM_STATE.ALREADY_ACTIVATED: {
        return <AlreadyActivated setCurrentFormState={setCurrentFormState} />
      }
    }
  }

  if (token) return null

  const background = isHigPage() ? <HigBackground/> : <OxefitBackground/>
  const logo = isHigPage() ? <HigLogo/> : <OxefitLogo/>
  return (
    <>
      {background}
      <Container>
        <Modal>
          <Content>
            {logo}
            {renderCurrentState()}
          </Content>
        </Modal>
      </Container>
    </>
  )
}

export default ActivateForm
