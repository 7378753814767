import React from "react"
import { ButtonContainer } from "./Button.styles"

interface ButtonProps {
  children?: React.ReactNode
  className?: string
  onClick?: () => void
  disabled?: boolean
}

const Button = ({ children, onClick, disabled, className }: ButtonProps) => {
  return (
    <ButtonContainer
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </ButtonContainer>
  )
}

export default Button
